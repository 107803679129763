
import {ICrudOption} from "@/types/m-ui-crud";
import {Component, Vue} from "vue-property-decorator";
import {systemLogApi, systemRoleQueryApi} from "@/apis/systemManage/role";
import {ISystemLog, ISystemLogQuery} from "@/apis/systemManage/log/types";
import {deepCopy} from "@/utils/common";

/**
 * @name: 系统管理-用户操作日志
 * @author: lili
 * @date: 2023-07-19 10:08
 * @description： 系统管理-用户操作日志
 * @update: 2023-07-19 10:08
 */
@Component({})
export default class RolePage extends Vue {
  // crud配置
  crudOption: ICrudOption = {
    menu: false,
    searchBox: true,
    column: [
      {
        label: "操作时间",
        prop: "addTime",
        align: "center",
        type: "daterange",
        search: true,
        width: 150
      },
      {
        label: "操作人",
        prop: "realName",
        align: "center",
        search: true,
        width: 150
      },
      {
        label: "操作内容",
        prop: "title",
        align: "center",
        overHidden: true
      },
      {
        label: "改动参数",
        prop: "params",
        align: "center",
        overHidden: true
      },
      {
        label: "执行时间",
        prop: "time",
        align: "center",
      },
      {
        label: "异常信息",
        prop: "exception",
        align: "center",
        overHidden: true
      },
      {
        label: "日志类型",
        prop: "type",
        align: "center",
        type: "select",
        dicData: [
          {
            label: "正常",
            value: "0"
          },
          {
            label: "错误",
            value: "1"
          }]
      }
    ],
  };
  query: ISystemLogQuery = {
    page: 1,
    limit: 10
  }
  // 新增/编辑 表单
  form: Partial<ISystemLog> = {}
  // 数据总数
  total: number = 0
  // 列表
  data: ISystemLog[] = []

  /**
   * 查询列表
   */
  getList() {
    let query = deepCopy(this.query)
    if (query.addTime && query.addTime.length == 2) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime
    systemLogApi(query).then(e => {
      this.total = e.total;
      this.data = e.list;
    })
  }

  mounted() {
    this.getList()
  }
}
